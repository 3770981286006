import React from "react";
import App from "./App";
import TokenState from "./Context/TokenState";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const Main = () => {
  return (
    <TokenState>
      <GoogleOAuthProvider clientId={ClientId}>
        <ToastContainer />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <App />
      </GoogleOAuthProvider>
    </TokenState>
  );
};

export default Main;
