import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const CreateFolderModal = ({ show, onHide, folderName, setFolderName, createFolder }) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>Create New Folder</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form>
        <Form.Group controlId="formFolderName">
          <Form.Label>Folder Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter folder name"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
          />
        </Form.Group>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Close
      </Button>
      <Button variant="primary" onClick={createFolder}>
        Create Folder
      </Button>
    </Modal.Footer>
  </Modal>
);

export default CreateFolderModal;
