import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const ShareFileModal = ({ show, onHide, shareFileData, setShareFileData, shareFile }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setShareFileData({ ...shareFileData, [name]: value });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Share File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formShareEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              name="email"
              value={shareFileData.email || ""}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formShareRole">
            <Form.Label>Role</Form.Label>
            <Form.Control
              as="select"
              name="role"
              value={shareFileData.role || ""}
              onChange={handleChange}
            >
              <option value="">Select role</option>
              <option value="reader">Reader</option>
              <option value="writer">Writer</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={shareFile}>
          Share File
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShareFileModal;
