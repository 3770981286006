import { useEffect, useState, useCallback } from "react";
import {
  createFolderOnDrive,
  deleteFileOnDrive,
  getDriveFiles,
  renameFileOnDrive,
  shareFileOnDrive,
  uploadFileOnDrive,
} from "../../../Api";
import {
  Dropdown,
  Container,
  Button,
  Row,
  Col,
  ProgressBar,
} from "react-bootstrap";
import { FaFile, FaFolder } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CreateFolderModal from "./CreateFolderModal";
import EditFileNameModal from "./EditFileNameModal";
import UploadFileModal from "./UploadFileModal";
import ShareFileModal from "./ShareFileModal";
import { toast } from "react-toastify";

const List = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [nextPageToken, setNextPageToken] = useState("");
  const [search, setSearch] = useState("");
  const [list, setList] = useState([]);
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [folderName, setFolderName] = useState("");

  const [showEditFileName, setShowEditFileName] = useState(false);
  const [editingFileData, setEditingFileData] = useState({});

  const [showShareFile, setShowShareFile] = useState(false);
  const [shareFileData, setShareFileData] = useState({});

  const [showUploadFile, setShowUploadFile] = useState(false);
  const [file, setFile] = useState(null);
  const [storageQuota, setStorageQuota] = useState({});

  const urlParams = new URLSearchParams(window.location.search);
  const folderId = urlParams.get("folderId");
  const [folderID, setFolderID] = useState(folderId);

  const navigate = useNavigate();

  const fetchFilesAndFolders = useCallback(() => {
    setLoading(true);
    console.log(folderID, search, nextPageToken, "datatatata");
    getDriveFiles(folderID, search, nextPageToken)
      .then((res) => {
        setLoading(false);
        setList(res.data.data.files);
        setNextPageToken(res.data.data.nextPageToken);
        setStorageQuota(res.data.data.storageQuota);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  }, [folderID, search]);

  const loadMore = () => {
    setLoadingMore(true);
    getDriveFiles(folderID, search, nextPageToken)
      .then((res) => {
        setLoadingMore(false);
        setList([...list, ...res.data.data.files]);
        setNextPageToken(res.data.data.nextPageToken);
        setStorageQuota(res.data.data.storageQuota);
        const element = document.getElementById("scrollableDiv");
        element.scrollTop = element.scrollHeight;
      })
      .catch((err) => {
        setLoadingMore(false);
        console.error(err);
      });
  };
  useEffect(() => {
    const timeoutId = setTimeout(fetchFilesAndFolders, 1000);
    return () => clearTimeout(timeoutId);
  }, [fetchFilesAndFolders]);

  const createFolder = () => {
    setLoading(true);
    createFolderOnDrive({ folderName, folderID })
      .then(() => {
        setLoading(false);
        fetchFilesAndFolders();
        setShowCreateFolder(false);
        toast.success("Folder created successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Error creating folder");
        console.error(err);
      });
  };

  const uploadFile = () => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = reader.result.split(",")[1];
      setLoading(true);
      uploadFileOnDrive({ file: base64, originalFileName: file.name, folderID })
        .then(() => {
          toast.success("File uploaded successfully");
          setLoading(false);
          fetchFilesAndFolders();
          setShowUploadFile(false);
        })
        .catch((err) => {
          toast.error("Error uploading file");
          setLoading(false);
          console.error(err);
        });
    };
  };

  const deleteFile = (fileId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        deleteFileOnDrive(fileId)
          .then(() => {
            setLoading(false);
            toast.success("File deleted successfully");
            fetchFilesAndFolders();
          })
          .catch((err) => {
            setLoading(false);
            toast.error("Error deleting file");
            console.error(err);
          });
      }
    });
  };

  const renameFile = (fileId, newFileName) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Rename it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        renameFileOnDrive({ fileId, newFileName })
          .then(() => {
            setLoading(false);
            toast.success("File renamed successfully");
            fetchFilesAndFolders();
            setShowEditFileName(false);
          })
          .catch((err) => {
            toast.error("Error renaming file");
            setLoading(false);
            console.error(err);
          });
      } else {
        setShowEditFileName(false);
        setEditingFileData({});
      }
    });
  };

  const editFileName = (fileId, filename) => {
    setEditingFileData({ fileId, filename });
    setShowEditFileName(true);
  };

  const goToFolder = (folderId) => {
    navigate(`/drive?folderId=${folderId}`);
    setFolderID(folderId);
  };

  const shareFileClick = (fileId) => {
    setShareFileData({ fileId });
    setShowShareFile(true);
  };

  const shareFile = () => {
    if (!shareFileData.email || !shareFileData.role || !shareFileData.fileId) {
      return;
    }
    setLoading(true);
    shareFileOnDrive(shareFileData)
      .then(() => {
        toast.success("File shared successfully");
        setLoading(false);
        fetchFilesAndFolders();
        setShowShareFile(false);
      })
      .catch((err) => {
        toast.error("Error sharing file");
        setLoading(false);
        console.error(err);
      });
  };

  return (
    <>
      <Container fluid style={{ height: "60vh" }}>
        <Row>
          <Col
            md={2}
            className="bg-light p-3 border-end"
            style={{ height: "60vh" }}
          >
            <div className="d-flex align-items-right mt-3 mb-3">
              <span>
                {`${
                  (storageQuota?.usage / (1024 * 1024 * 1024)).toFixed(2) >= 1
                    ? `${(storageQuota?.usage / (1024 * 1024 * 1024)).toFixed(
                        2
                      )} GB`
                    : `${(storageQuota?.usage / (1024 * 1024)).toFixed(2)} MB`
                } of ${
                  (storageQuota?.limit / (1024 * 1024 * 1024)).toFixed(2) >= 1
                    ? `${(storageQuota?.limit / (1024 * 1024 * 1024)).toFixed(
                        2
                      )} GB used`
                    : `${(storageQuota?.limit / (1024 * 1024)).toFixed(
                        2
                      )} MB used`
                }`}
              </span>
            </div>
            <div className="d-flex align-items-center mt-3 mb-3">
              <ProgressBar
                now={(storageQuota?.usage / storageQuota?.limit) * 100}
                className="progress-bar-striped progress-bar-animated"
                style={{ width: "100%", height: "1.5rem" }}
              />
            </div>

            <div className="row m-1 mb-3 mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                style={{ width: "100%" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <Button
              variant="primary"
              className="w-100 mb-3"
              onClick={() => setShowCreateFolder(true)}
            >
              + Create Folder
            </Button>
            <Button
              variant="primary"
              className="w-100 mb-3"
              onClick={() => setShowUploadFile(true)}
            >
              + Upload File
            </Button>
          </Col>
          {loading ? (
            <Col
              md={10}
              className="p-3"
              style={{ height: "60vh", overflow: "scroll" }}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "60vh" }}
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </Col>
          ) : (
            <>
              <Col
                md={10}
                className="p-3"
                id="scrollableDiv"
                style={{ height: "60vh", overflow: "scroll" }}
              >
                <Row>
                  {list?.map((item) => (
                    <Col key={item.id} sm={6} md={4} lg={3} className="mb-3">
                      {item?.mimeType ===
                      "application/vnd.google-apps.folder" ? (
                        <div className="border p-3 bg-white rounded shadow-sm d-flex align-items-center justify-content-between">
                          <FaFolder size={24} className="text-warning" />
                          <span>
                            <a href="#" onClick={() => goToFolder(item.id)}>
                              {item.name.length > 20
                                ? item.name.substring(0, 20) + "..."
                                : item.name}
                            </a>
                          </span>
                          <Dropdown align="end">
                            <Dropdown.Toggle
                              variant="link"
                              id="dropdown-folder"
                              className="text-muted"
                            ></Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#"
                                onClick={() => editFileName(item.id, item.name)}
                              >
                                Rename
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => shareFileClick(item.id)}
                              >
                                Share
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => deleteFile(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : (
                        <div className="border p-3 bg-white rounded shadow-sm d-flex align-items-center justify-content-between">
                          <FaFile size={24} className="text-primary" />
                          <a
                            href={item.webViewLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.name.length > 20
                              ? item.name.substring(0, 20) + "..."
                              : item.name}
                          </a>
                          <Dropdown align="end">
                            <Dropdown.Toggle
                              variant="link"
                              id="dropdown-file"
                              className="text-muted"
                            ></Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href="#"
                                onClick={() => editFileName(item.id, item.name)}
                              >
                                Rename
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => shareFileClick(item.id)}
                              >
                                Share
                              </Dropdown.Item>
                              <Dropdown.Item
                                href="#"
                                onClick={() => deleteFile(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                    </Col>
                  ))}
                  {nextPageToken && (
                    <div className="d-flex justify-content-center">
                      <Button onClick={loadMore} disabled={loading}>
                        {loadingMore ? "Loading..." : "Load More"}
                      </Button>
                    </div>
                  )}
                </Row>
              </Col>
            </>
          )}
        </Row>
      </Container>

      {showCreateFolder && (
        <CreateFolderModal
          show={showCreateFolder}
          onHide={() => setShowCreateFolder(false)}
          folderName={folderName}
          setFolderName={setFolderName}
          createFolder={createFolder}
        />
      )}

      {showEditFileName && (
        <EditFileNameModal
          show={showEditFileName}
          onHide={() => setShowEditFileName(false)}
          editingFileData={editingFileData}
          setEditingFileData={setEditingFileData}
          renameFile={renameFile}
        />
      )}

      {showUploadFile && (
        <UploadFileModal
          show={showUploadFile}
          onHide={() => setShowUploadFile(false)}
          setFile={setFile}
          uploadFile={uploadFile}
        />
      )}

      {showShareFile && (
        <ShareFileModal
          show={showShareFile}
          onHide={() => setShowShareFile(false)}
          shareFileData={shareFileData}
          setShareFileData={setShareFileData}
          shareFile={shareFile}
        />
      )}
    </>
  );
};

export default List;
