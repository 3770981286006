import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const UploadFileModal = ({ show, onHide, setFile, uploadFile }) => {
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFileUpload">
            <Form.Label>Choose File</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={uploadFile}>
          Upload File
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UploadFileModal;
