import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const EditFileNameModal = ({ show, onHide, editingFileData, setEditingFileData, renameFile }) => {
  const handleChange = (e) => {
    setEditingFileData({ ...editingFileData, filename: e.target.value });
  };

  const handleRename = () => {
    renameFile(editingFileData.fileId, editingFileData.filename);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Rename File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFileName">
            <Form.Label>File Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new file name"
              value={editingFileData.filename}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleRename}>
          Rename File
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditFileNameModal;
